import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Suspense, useState } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';

import { defaultApplicationsUrl } from '../lib/misc';

import Logo from '@/assets/images/logo.svg';
import Loader from '@/components/Loader';

const Root = styled('div')(() => ({
  display: 'grid',
  height: '100vh',
  gridTemplateColumns: 'auto minmax(0, 1fr)',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
}));

export const AgentPortalLayout = () => {
  return (
    <Root>
      <Box>
        <Sidebar />
      </Box>
      <ContentContainer>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </ContentContainer>
    </Root>
  );
};

const LogoLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
  alignItems: 'flex-end',
  color: 'inherit',
  textDecoration: 'none',
}));

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 0,
        color: 'inherit',
        position: 'sticky',
        top: 0,
        height: '100vh',
        width: collapsed ? 40 : 200,
        transition: 'width 0.3s',
        overflow: 'hidden',
      }}
    >
      <IconButton onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
      <LogoLink
        to="/agent-portal"
        sx={(theme) => ({
          width: collapsed ? 24 : 'auto',
          overflow: 'hidden',
          padding: collapsed ? theme.spacing(1.5, 2) : theme.spacing(1.5, 2),
          marginLeft: collapsed ? theme.spacing(-0.5) : 0,
        })}
      >
        <img
          src={Logo}
          alt="Scoopr"
          width={109}
          height={24}
          style={{ flex: 1, height: 'auto' }}
        />
        <Typography sx={{ fontSize: '0.875rem', fontWeight: '700' }}>
          Agent
        </Typography>
      </LogoLink>

      <Stack alignItems="stretch" gap={0.5} sx={{ px: collapsed ? 0 : 1 }}>
        <SidebarLink
          to={defaultApplicationsUrl}
          sx={{ px: collapsed ? 1.5 : 1, py: 1 }}
        >
          {collapsed ? 'A' : 'Applications'}
        </SidebarLink>
      </Stack>
    </Paper>
  );
};

const SidebarLink = styled(NavLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  fontWeight: '600',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  '&.active': {
    color: theme.palette.primary.main,
    fontWeight: '700',
  },
}));
