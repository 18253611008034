import ky from 'ky';

import { fetchWithError } from './fetch';

import { API } from '@/services/Config';
import { logoutRedirect } from '@/services/new-utils';
import {
  getCurrentTokensOperation,
  getTokens,
  isTokenExpired,
  obtainNewTokens,
  refreshTokens,
  removeTokens,
  saveTokens,
  type Tokens,
} from '@/services/tokens';

export const apiClient = ky.create({
  fetch: fetchWithError,
  prefixUrl: API.url,
  retry: 0,
  credentials: 'include',
  hooks: {
    beforeRequest: [
      async (request) => {
        await getCurrentTokensOperation();
        const token = getTokens();
        if (token) {
          request.headers.set('authorization', `Bearer ${token.accessToken}`);
        }
      },
    ],
    afterResponse: [
      async (request, _options, response) => {
        if (response.status === 401) {
          const tokens = getTokens();
          let newTokens: Tokens | undefined;

          if (canRefreshTokens(tokens)) {
            try {
              newTokens = await refreshTokens();
            } catch {}
          }

          if (
            !newTokens &&
            canObtainNewTokens(
              request.url,
              await request
                .clone()
                .json()
                .catch(() => undefined),
            )
          ) {
            try {
              newTokens = await obtainNewTokens();
            } catch {}
          }

          if (newTokens) {
            saveTokens(newTokens);
            return apiClient(request);
          } else {
            // There could be an update in the background, retry with new tokens
            if (
              getCurrentTokensOperation() ||
              tokens?.refreshToken !== getTokens()?.refreshToken
            ) {
              return apiClient(request);
            }

            removeTokens();
            logoutRedirect();
            console.error('Could not refresh or obtain new token');
          }
        }
      },
    ],
  },
});

export const canObtainNewTokens = (
  requestUrl: string | undefined,
  requestBody: Record<string, any> | undefined,
): boolean => {
  if (window.location.pathname.startsWith('/agent-portal')) {
    return false;
  }

  // Legion flow starting request
  if (
    requestUrl === `${API.url}/brain/api/legion/` &&
    ['init', 'goal'].includes(requestBody?.content_type)
  ) {
    return true;
  }

  // Winners page
  if (
    requestUrl === `${API.url}/events_management/events/march_2024_campaign/`
  ) {
    return true;
  }

  return false;
};

export const canRefreshTokens = (
  tokens: Tokens | null | undefined,
): tokens is Tokens => {
  if (tokens?.refreshToken) {
    return !isTokenExpired(tokens.refreshToken);
  }

  return false;
};
