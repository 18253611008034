import { addDays, format } from 'date-fns';

import routes from '@/assets/constants/routes-no';
import { withSearchParams } from '@/lib/utils';

export const defaultApplicationsUrl = withSearchParams(
  routes.AGENT_PORTAL_APPLICATIONS,
  {
    sort: 'created_at',
    desc: 'true',
    f_created_at: JSON.stringify([
      format(addDays(new Date(), -14), 'yyyy-MM-dd'),
      null,
    ]),
  },
);
